import React from 'react';
import { useTheme } from 'styled-components';
import { TGridProps, TGridItemProps, TGridItemBreakpoint } from './Grid.type';
import { TSpacingValue } from '@dist-property-frontend/configs';
import * as Styled from './Grid.styled';

const getGridItemBreakpoints = (
  xs?: TGridItemBreakpoint,
  sm?: TGridItemBreakpoint,
  md?: TGridItemBreakpoint,
  lg: TGridItemBreakpoint = 'auto',
) => ({
  xs: xs || sm || md || lg,
  sm: sm || md || lg,
  md: md || lg,
  lg: lg,
});

const getGridSpacingsBreakpoints = (
  xs?: TSpacingValue,
  sm?: TSpacingValue,
  md?: TSpacingValue,
  lg: TSpacingValue = 'S0',
) => ({
  xs: xs || sm || md || lg,
  sm: sm || md || lg,
  md: md || lg,
  lg: lg,
});

export const Grid = ({
  columns = 12,
  alignItems,
  justifyContent,
  children,
  gridGap,
  hideElement,
}: TGridProps) => {
  const theme = useTheme();
  const childrenWithProps = React.Children.map(children, (child) =>
    React.isValidElement(child)
      ? React.cloneElement<any>(child, { gridColumns: columns })
      : child,
  );

  return (
    <Styled.Grid
      columns={columns}
      gridGap={{
        ...getGridSpacingsBreakpoints(
          gridGap?.xs,
          gridGap?.sm,
          gridGap?.md,
          gridGap?.lg,
        ),
      }}
      alignItems={alignItems}
      justifyContent={justifyContent}
      hideElement={hideElement}
      theme={theme}
    >
      {childrenWithProps}
    </Styled.Grid>
  );
};

export const GridItem = (props: TGridItemProps) => {
  const {
    xs,
    md,
    sm,
    lg,
    children,
    startColumn,
    spanColumns,
    rowStart,
    rowSpan,
    verticalSelfAlignment,
    itemTextAlign,
    hideElement,
    maxWrappedLines,
  } = props;
  const theme = useTheme();

  return (
    <Styled.GridItem
      {...getGridItemBreakpoints(xs, sm, md, lg)}
      startColumn={startColumn}
      spanColumns={spanColumns}
      rowStart={rowStart}
      rowSpan={rowSpan}
      hideElement={hideElement}
      itemTextAlign={itemTextAlign}
      verticalSelfAlignment={verticalSelfAlignment}
      maxWrappedLines={maxWrappedLines}
      theme={theme}
    >
      {children}
    </Styled.GridItem>
  );
};

export default { Grid, GridItem };
