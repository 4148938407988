import styled from 'styled-components';
import * as Types from './StickyButton.types';

//semi-transparent white container that sticks to bottom of screen
export const Overlay = styled.div`
  bottom: env(safe-area-inset-bottom, 0);
  position: fixed;
  width: 100%;
  z-index: 100;
`;

export const ButtonContainer = styled.div<Types.TStickyButtonStyle>`
  max-width: 648px;
  margin: auto;
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.toolkit.spacing.M16};
  padding: ${({ theme }) => theme.toolkit.spacing.S12};
  height: 100%;
  grid-template-columns: 1fr;

  // Automatically adds a second column to the grid if a second button exists
  grid-auto-flow: column;
  grid-auto-columns: 1fr;

  // 'fill' property only affects desktop screen
  // setting to fill will mean buttons fill their container
  @media ${({ theme }) => theme.toolkit.mediaQuery.large} {
    display: ${({ fill }) => (fill ? 'grid' : 'flex')};
  }
`;
