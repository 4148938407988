import Map, { Marker } from 'react-map-gl/maplibre';
import { Icon } from '../../atoms';
import * as Styled from './Map.styled';
import { MapIcons } from './MapIcons';
import { useMap } from './Map.hook';
import { TInterativeMap } from './Map.types';
import * as Atom from '../../atoms';

const InteractiveMap = (props: TInterativeMap) => {
  const { id, variant, draggable = true } = props;

  const {
    mapRef,
    viewport,
    selected,
    onMapClick,
    handleZoomIn,
    handleZoomOut,
    onMarkerDragEnd,
  } = useMap(props);

  return (
    <Styled.Map data-tracking="map-interaction">
      <Map
        id={id}
        initialViewState={viewport}
        ref={mapRef}
        mapStyle="https://api.maptiler.com/maps/51896624-22c9-466c-a329-a94c71f30a0d/style.json?key=94POrAmV6YVyhXBXGw8R"
        onClick={onMapClick}
      >
        {!selected && draggable && (
          <Marker
            longitude={props.marker.longitude}
            latitude={props.marker.latitude}
            anchor="bottom"
            offset={[0, 55.33]}
          >
            {MapIcons['MAPPIN_BIG']}
          </Marker>
        )}
        <Marker
          longitude={props.marker.longitude}
          latitude={props.marker.latitude}
          anchor="bottom"
          draggable={draggable}
          onDragEnd={onMarkerDragEnd}
          offset={[0, 1.33]}
        >
          <Styled.MapIcon draggable={draggable}>
            {MapIcons['MAPPIN']}
          </Styled.MapIcon>
        </Marker>
        <Styled.ControlWrapper>
          <Atom.Button
            buttonType={Atom.ButtonTypes.TERTIARY}
            buttonSize={
              variant === 'medium'
                ? Atom.ButtonSizes.SMALL
                : Atom.ButtonSizes.X_SMALL
            }
            Icon={<Icon icon={'PLUS'} size={16} />}
            onClick={handleZoomIn}
          />
          <Atom.Button
            buttonType={Atom.ButtonTypes.TERTIARY}
            buttonSize={
              variant === 'medium'
                ? Atom.ButtonSizes.SMALL
                : Atom.ButtonSizes.X_SMALL
            }
            Icon={<Icon icon={'MINUS'} size={16} />}
            onClick={handleZoomOut}
          />
        </Styled.ControlWrapper>
      </Map>
    </Styled.Map>
  );
};

export { InteractiveMap };
