import { getContrastYIQ } from '../../../../../helpers/ContrastColorChecker';
import { MaskedContainer, MaskedContainerSize } from '../../../atoms';
import { BrandBanner, BrandBannerSize } from '../../../organisms';
import {
  ResponsiveLogo,
  ResponsiveSubtitle,
  ResponsiveTitle,
} from './CustomCardBanner.styled';

type CustomCardBannerType = {
  props: CardBannerUIType;
};
export type CardBannerUIType = {
  rightMaskedImage: string;
  rightMaskedImageAlt: string;
  rightTitle: string;
  rightSubtitle: string;
  leftSlot: string;
  fallbackText: string;
  backgroundColor: string;
  size: BrandBannerSize;
  dataTracking?: { [key: string]: string };
  maskedContainerSize: {
    size: MaskedContainerSize;
    mobileSize: MaskedContainerSize;
    outline: boolean;
  };
};

export const CustomCardBanner = (
  bannerProps: CustomCardBannerType,
): JSX.Element => {
  const fontColor = bannerProps.props?.backgroundColor
    ? getContrastYIQ(bannerProps.props.backgroundColor)
    : '#333333';
  const dataTracking = bannerProps?.props?.dataTracking;
  return (
    <BrandBanner
      dataTracking={dataTracking}
      size={bannerProps.props?.size}
      backgroundColor={bannerProps?.props?.backgroundColor}
      fontColor={fontColor}
      rightSideImage={
        bannerProps.props?.rightMaskedImage ? (
          <MaskedContainer
            dataTracking={dataTracking?.['rightMaskedImage']}
            imageAlt={bannerProps.props?.rightMaskedImageAlt}
            image={bannerProps.props?.rightMaskedImage}
            outline={bannerProps.props?.maskedContainerSize?.outline}
            size={bannerProps.props?.maskedContainerSize?.size}
            mobileSize={bannerProps.props?.maskedContainerSize.mobileSize}
          />
        ) : undefined
      }
      rightTitle={
        <ResponsiveTitle
          {...(dataTracking && {
            'data-tracking': dataTracking?.['rightTitle'],
          })}
        >
          {bannerProps.props?.rightTitle}
        </ResponsiveTitle>
      }
      rightSubtitle={
        <ResponsiveSubtitle
          {...(dataTracking && {
            'data-tracking': dataTracking?.['rightSubtitle'],
          })}
        >
          {bannerProps.props?.rightSubtitle}
        </ResponsiveSubtitle>
      }
      leftSideLogo={
        bannerProps?.props?.leftSlot ? (
          <img src={bannerProps.props?.leftSlot} alt="Logo" />
        ) : (
          <ResponsiveLogo>{bannerProps.props?.fallbackText}</ResponsiveLogo>
        )
      }
    />
  );
};
