import { forwardRef } from 'react';
import * as Styled from './RadioButton.styled';
import * as Types from './RadioButton.types';
import * as Atom from '../../atoms';
import { useTheme } from 'styled-components';

export const RadioButton = forwardRef<HTMLInputElement, Types.TRadioButton>(
  (props: Types.TRadioButton, ref) => {
    const {
      value,
      inputLabel,
      secondary,
      subtext,
      icon,
      background = true,
      hasError = false,
      checked = false,
      disabled = false,
      onChange,
      ...rest
    } = props;
    const theme = useTheme();
    const greyTheme = theme.toolkit.brand.grey;
    return (
      <Styled.RadioButtonWrapper
        background={background}
        checked={checked}
        disabled={disabled}
      >
        <Styled.StyledRadioButton
          type={'radio'}
          hasError={hasError}
          disabled={disabled}
          checked={checked}
          ref={ref}
          {...rest}
          value={value}
          onChange={onChange}
        />
        <Styled.RadioButtonText>
          <Styled.RadioButtonLabel>
            <Atom.Text
              variant={'span'}
              size={Atom.TextSize.S14}
              fontWeight={Atom.TextFontWeight.REGULAR}
              textColor={disabled ? greyTheme?.BASE : greyTheme?.DARKER}
            >
              {inputLabel}
            </Atom.Text>
            {secondary && (
              <Styled.RadioButtonSecondary>
                <Atom.Text
                  variant={'span'}
                  size={Atom.TextSize.S14}
                  fontWeight={Atom.TextFontWeight.REGULAR}
                  textColor={disabled ? greyTheme?.BASE : greyTheme?.DARK}
                >
                  {secondary}
                </Atom.Text>
              </Styled.RadioButtonSecondary>
            )}
          </Styled.RadioButtonLabel>
          {subtext && (
            <Styled.RadioButtonSubtext>
              <Atom.Text
                variant={'span'}
                size={Atom.TextSize.S12}
                fontWeight={Atom.TextFontWeight.REGULAR}
                textColor={disabled ? greyTheme?.BASE : greyTheme?.DARK}
              >
                {subtext}
              </Atom.Text>
            </Styled.RadioButtonSubtext>
          )}
        </Styled.RadioButtonText>
        {icon && <Styled.IconWrapper>{icon}</Styled.IconWrapper>}
      </Styled.RadioButtonWrapper>
    );
  },
);
