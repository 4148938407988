import styled from 'styled-components';

export const RadioButtonWrapper = styled.label<{
  checked?: boolean;
  background?: boolean;
  disabled?: boolean;
}>`
  text-align: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  touch-action: manipulation;
  height: 100%;

  ${({ theme, background, checked, disabled }) =>
    background
      ? `
      background-color: ${
        disabled
          ? theme.toolkit.brand.grey?.LIGHTEST
          : theme.toolkit.brand.grey?.TEXTCONTRAST
      };
      padding: ${theme.toolkit.spacing.M16};

      box-shadow: ${
        checked
          ? `inset ${
              disabled
                ? `0 0 0 0.0625rem ${theme.toolkit.brand.grey?.LIGHTER}`
                : `0 0 0 ${theme.toolkit.spacing.S2} ${theme.toolkit.brand.grey?.DARKER}`
            }`
          : `inset 0 0 0 0.0625rem ${theme.toolkit.brand.grey?.LIGHTER}`
      };
      border-radius: ${theme.toolkit.spacing.S4};
      `
      : ''};
`;

export const StyledRadioButton = styled.input<{
  hasError: boolean;
  checked: boolean;
  disabled: boolean;
}>`
  appearance: none;
  -webkit-appearance: none;
  min-width: ${({ theme }) => theme.toolkit.spacing.M20};
  height: ${({ theme }) => theme.toolkit.spacing.M20};
  cursor: pointer;
  border-radius: 50%;
  align-self: center;
  background: ${({ theme }) => theme.toolkit.brand.grey?.TEXTCONTRAST};
  border: 0.0625rem solid
    ${({ theme, hasError }) =>
      hasError
        ? theme.toolkit.brand.error?.BASE
        : theme.toolkit.brand.grey?.LIGHT};

  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: ${({ theme }) =>
      `0 0 0 ${theme.toolkit.spacing.S4} ${theme.toolkit.brand.grey?.LIGHT}`};
  }

  &:checked {
    background: ${({ theme }) => theme.toolkit.brand.grey?.TEXTCONTRAST};
    border: ${({ theme }) =>
      `${theme.toolkit.spacing.S2} solid ${theme.toolkit.brand.grey?.DARKER}`};
    ::before {
      content: '';
      width: ${({ theme }) => theme.toolkit.spacing.S12};
      height: ${({ theme }) => theme.toolkit.spacing.S12};
      border-radius: 50%;
      background-color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
      position: absolute;
      transform: ${({ theme }) =>
        `translate(${theme.toolkit.spacing.S2}, ${theme.toolkit.spacing.S2})`};
      display: block;
    }
  }

  &:disabled {
    background: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTEST};
    border: 0.0625rem solid ${({ theme }) => theme.toolkit.brand.grey?.LIGHTER};
  }

  ${({ checked, theme }) =>
    checked &&
    `    
    background: ${theme.toolkit.brand.grey?.TEXTCONTRAST};
    border: ${theme.toolkit.spacing.S2} solid ${theme.toolkit.brand.grey?.DARKER};
    ::before {
      content: '';
      width: ${theme.toolkit.spacing.S12};
      height: ${theme.toolkit.spacing.S12};
      border-radius: 50%;
      background-color: ${theme.toolkit.brand.grey?.DARKER};
      position: absolute;
      transform: 
        translate(${theme.toolkit.spacing.S2}, ${theme.toolkit.spacing.S2});
      display: block;
    }
    `}
  ${({ disabled, theme }) =>
    disabled &&
    `
    background: ${theme.toolkit.brand.grey?.LIGHTEST};
    border: 0.0625rem solid ${theme.toolkit.brand.grey?.LIGHTER};`}
`;

export const RadioButtonText = styled.div`
  content: '';
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding-left: ${({ theme }) => theme.toolkit.spacing.S12};
`;

export const RadioButtonLabel = styled.div`
  display: flex;
`;

export const RadioButtonSecondary = styled.div`
  margin-left: ${({ theme }) => theme.toolkit.spacing.S8};
`;

export const RadioButtonSubtext = styled.div`
  margin-top: ${({ theme }) => theme.toolkit.spacing.S4};
`;

export const IconWrapper = styled.div`
  padding-left: ${({ theme }) => theme.toolkit.spacing.S12};
  display: flex;
  margin-left: auto;
`;
